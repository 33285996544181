import { computed, ref, reactive, nextTick } from "vue";
import { message } from "ant-design-vue";
import { Moment } from "moment";
import {
  findByGroupCode,
  confirmDeadlines,
  getModelYear,
} from "@/API/communication/appeal";
import { useStore } from "vuex";
import { getUser } from "@/API/checking/checkingManagement";

const useAppeal = () => {
  const spaceSize = ref<number>(6);
  const tableData = reactive({
    toBeConfirmedData: [],
    toBeConfirmedDeductionData: [],
    submitAppealDoc: [],
    finished: [],
  });

  const vinNo = ref<string | null>("");
  const vinNos = ref<string[]>([]);
  // 批量查询
  const batchQuery = reactive({
    batchQueryTitle: "",
    batchQueryModal: false,
    batchQueryListTitle: "",
  });
  //Modal的输入框
  const textContentNo = ref<string>("");
  //输入框，用于控制焦点
  const input = ref();
  //显示的VIN数组
  const textNoArray = ref<string[]>([]);
  //空占位
  const batchPlaceholder = ref(["", "", "", "", "", ""]);

  const queryDealerTrade = ref('')
  const cleanEmptyEleArray = (array: Array<string>) => {
    if (array.length > 1) {
      const tempArray = array.filter((item) => item !== "");
      tempArray.length || tempArray.push("");
      return tempArray;
    } else {
      return array;
    }
  };
  // 设置批量查询modal 的 占位div
  const setBatchPlaceholder = () => {
    if (textNoArray.value.length) {
      batchPlaceholder.value = [];
    } else {
      batchPlaceholder.value = ["", "", "", "", "", ""];
    }
  };

  // 批量查询按钮事件
  const batchQueryEvent = () => {
    batchQuery.batchQueryModal = true;
    batchQuery.batchQueryTitle = "车架号批量查询";
    batchQuery.batchQueryListTitle = "车架号列表";
    textNoArray.value =
      cleanEmptyEleArray(vinNos.value)[0] === ""
        ? []
        : cleanEmptyEleArray(vinNos.value);
    setBatchPlaceholder();
    const dom = document.getElementsByClassName("list-content")[0];
    nextTick(() => {
      dom && dom.scrollTo(0, textNoArray.value.length * 36);
      input.value.focus();
    });
  };
  // 批量文本内容
  const textChangEvent = () => {
    if (textContentNo.value.length < 5) {
      message.error("请从Excel中粘贴到文本框");
      textContentNo.value = "";
      return;
    }
    textNoArray.value = textNoArray.value.concat(
      textContentNo.value.replace(/\r|\n|\s/g, ",").split(",")
    );
    const dom = document.getElementsByClassName("list-content")[0];
    textContentNo.value = "";
    vinNos.value = [];
    vinNos.value = vinNos.value.concat(textNoArray.value);
    setBatchPlaceholder();
    nextTick(() => {
      dom.scrollTo(0, dom.scrollHeight + 40);
      input.value.focus();
    });
  };
  // 关闭批量查询Modal
  const closeBatchQueryModal = () => {
    vinNo.value = cleanEmptyEleArray(vinNos.value)[0];
  };
  // 清除批量查询
  const clearBatchQuery = () => {
    vinNos.value = [""];
    textNoArray.value =
      cleanEmptyEleArray(vinNos.value)[0] === ""
        ? []
        : cleanEmptyEleArray(vinNos.value);
    setBatchPlaceholder();
    vinNo.value = null;
    input.value.focus();
  };

  const model = ref<string>("");
  const modelYear = ref<string | undefined>(undefined);
  const modelYearList = ref<any[]>([
    { title: "2021", value: 1 },
    { title: "2022", value: 2 },
    { title: "2023", value: 3 },
  ]);
  const getModelYearList = () => {
    getModelYear({ property: "modelYear" }).then((res: any) => {
      modelYearList.value = res.filter((year: any) => {
        return year !== null;
      });
    });
  };

  const submissionDate = ref<Moment[]>([]);
  const programCode = ref<string>("");
  const programName = ref<string>("");
  const checkResult = ref<string | undefined>(undefined);
  const checkResultList = ref<any[]>([]);
  const getCheckResult = () => {
    findByGroupCode({ groupCode: "checking_result" }).then((res: any) => {
      checkResultList.value = res.filter((item: any) => item.itemCode !== 0);
    });
  };

  const communicationStatus = ref<string | undefined>(undefined);
  const communicationStatusList = ref<any[]>([]);
  const getCommunicationStatus = () => {
    findByGroupCode({ groupCode: "communication_status" }).then((res: any) => {
      communicationStatusList.value = res;
    });
  };

  const appealStatus = ref<string | undefined>(undefined);
  const appealStatusList = ref<any[]>([]);
  const getAppealTypeList = () => {
    findByGroupCode({ groupCode: "appeal_status" }).then((res: any) => {
      appealStatusList.value = res;
    });
  };

  const reset = () => {
    vinNo.value = "";
    vinNos.value = [];
    model.value = "";
    modelYear.value = undefined;
    submissionDate.value = [];
    programCode.value = "";
    programName.value = "";
    checkResult.value = undefined;
    communicationStatus.value = undefined;
    appealStatus.value = undefined;
    queryDealerTrade.value = ''
  };

  //? 页面初始化相关
  const dealerCode = ref<string>("");
  const buList = ref<string[]>([]);
  // const dateList = ref<{ date: string; selected: boolean }[]>([]);
  const confirmDateList = ref<{ date: string; selected: boolean }[]>([]);
  const exemptionDateList = ref<{ date: string; selected: boolean }[]>([]);
  const generateDeadlinesStr = () => {
    return [
      ...confirmDateList.value
        .filter((date) => {
          return date.selected;
        })
        .map((date) => {
          return date.date;
        }),
      ...exemptionDateList.value
        .filter((date) => {
          return date.selected;
        })
        .map((date) => {
          return date.date;
        }),
    ];
  };

  const selectedDate = ref<string[]>([]);
  const dateMap = (dates: any) => {
    return dates.map((date: string) => {
      if (selectedDate.value.length === 0) {
        return {
          date,
          selected: true,
        };
      } else {
        return {
          date,
          selected: selectedDate.value.includes(date),
        };
      }
    });
  };
  const init = (func: Function) => {
    getAppealTypeList();
    getCommunicationStatus();
    getCheckResult();
    getModelYearList();

    const store = useStore();
    dealerCode.value = (store.state.user as any).organization.entityCode;

    getUser()
      .then((res) => {
        buList.value = res.buList
          .filter((ele: any) => {
            return ele.parentId === null;
          })
          .map((ele: any) => {
            return ele.nameCn;
          });
      })
      .then(() => {
        const params = {
          bus: buList.value.join(","),
          dealerCode: dealerCode.value,
        };
        return confirmDeadlines(params).then((res: any) => {
          confirmDateList.value = dateMap(res["confirm"]);
          exemptionDateList.value = dateMap(res["exemption"]);
        });
      })
      .then(() => {
        func();
      });
  };

  return {
    tableData,
    spaceSize,
    batchPlaceholder,
    textContentNo,
    input,
    clearBatchQuery,
    batchQuery,
    batchQueryEvent,
    textNoArray,
    closeBatchQueryModal,
    textChangEvent,
    confirmDateList,
    exemptionDateList,
    vinNo,
    vinNos,
    model,
    modelYear,
    submissionDate,
    programCode,
    programName,
    checkResult,
    checkResultList,
    communicationStatus,
    communicationStatusList,
    appealStatus,
    appealStatusList,
    queryDealerTrade,
    modelYearList,
    reset,
    selectedDate,
    init,
    dealerCode,
    buList,
    generateDeadlinesStr,
  };
};

export default useAppeal;
